
import { defineComponent } from 'vue';
import PageHeader from '@/components/PageHeader.vue';
import BackBtn from '@/components/BackBtn.vue';
import LocomotiveScroll from 'locomotive-scroll';
import newsJson from '@/assets/data/news.json';

type News = {
  id: string;
  year: string;
  date: string;
  media: string;
  title: string;
  client: string;
  link: string;
}

export default defineComponent({
  name: 'About',
  components: {
    PageHeader, BackBtn
  },
  data(){
    return {
      news: newsJson
    }
  },
  methods: {
    getNews(year:string){
      const news: News[] = [];
      for(const n of this.news){
        if(n.year == year){
          news.push(n);
        }
      }
      return news;
    },
    getNewsWidth(year: string){
      const news: News[] = [];
      for(const n of this.news){
        if(n.year == year){
          news.push(n);
        }
      }
      return ((Math.ceil(news.length/2)) * (750+50)/1920 * 100) + 'vw';
    }
  },
  mounted() {
    let locomotive = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth: true,
      smartphone: {
        smooth: false,
        direction: 'vertical'
      },
      direction: 'horizontal',
      multiplier: 1,
      horizontalGesture: true
    });

    //スクロール時のエフェクト
    let scroll = {cache: 0, current: 0};
    const allItems =  document.querySelectorAll<HTMLElement>('.about__news-item');
    // const allYears =  document.querySelectorAll<HTMLElement>('.about__news-year');
    // const allTtl =  document.querySelectorAll<HTMLElement>('.about-ttl-wrapper');
    
    locomotive.on('scroll', function(obj:any){
      scroll.current = obj.scroll.x;
      const distance = scroll.current - scroll.cache;
      scroll.cache = scroll.current;
      const skewVal = map(distance, -50, 50, -15, 15);
      // allImgs.forEach(el => el<HTMLElement>.style.transform = 'skewX('+clamp(skewVal, -15, 15)+'deg)');
      // allImgs.forEach(el:HTMLElement){
      //   el.style.transform = 'skewX('+clamp(skewVal, -15, 15)+'deg)';
      // }
      // for(let i=0; i<allItems.length; i++){
      //   allItems[i].style.transform = 'skewX('+clamp(-skewVal, -5, 5)+'deg)';
      // }
      // for(let i=0; i<allYears.length; i++){
      //   allYears[i].style.transform = 'skewX('+clamp(-skewVal, -15, 15)+'deg)';
      // }
      // for(let i=0; i<allTtl.length; i++){
      //   allTtl[i].style.transform = 'skewX('+clamp(-skewVal, -10, 10)+'deg)';
      // }

      function map(x:number, a:number, b:number, c:number, d:number){
        return (x - a) * (d - c) / (b - a) + c;
      }
      function clamp(num:number, min:number, max:number) {
        return num <= min ? min : num >= max ? max : num
      }

      // if(typeof obj.currentElements['test'] === 'object') {
      //   let progress = obj.currentElements['test'].progress;
      //   console.log(progress);
      //   // ouput log example: 0.34
      //   // gsap example : myGsapAnimation.progress(progress);
      // }
      // console.log(typeof(<Element>obj.currentElements['test']));
      // console.log('scroll:' + obj.currentElements['test'].progress);


      const body = document.querySelector<HTMLElement>("body")
      const container = document.querySelector<HTMLElement>(".about__body")
      let bar = document.querySelector<HTMLElement>(".progress span");
      bar!.style.width = (Number(obj.scroll.x)/ (Number(container!.clientWidth)-Number(body!.clientWidth)-150))* 100 + '%';
      // bar!.style.width = (Number(obj.scroll.x)/ (Number(container!.clientWidth)))* 100 + '%';
    });
    locomotive.update();
  },
  beforeRouteEnter (to, from, next) {
    document.querySelector('html')?.classList.add('about-page');
    next();
  },
  beforeRouteLeave (to, from, next) {
    document.querySelector('html')?.classList.remove('about-page');
    next();
  }
});
